<div class="card standard-widget">
  <div class="card-header">
    <div class="card-title">
      <div
        *ngLet="isControlBarVisible$ | async as isControlBarVisible"
        [class.pr-x4]="isControlBarVisible"
        class="clr-row"
      >
        <div class="clr-col-sm card-title-text">
          <dpa-ellipsis-with-tooltip>
            <span>
              {{ chartTitle }}
            </span>
          </dpa-ellipsis-with-tooltip>
          @for (tooltipModel of tooltips; track tooltipModel) {
            <dpa-tooltip-viewer
              [tooltip]="tooltipModel"
              class="title-tooltip"
            />
          }
        </div>
        <div class="group-by-template-container">
          @if (groupByTemplate) {
            <ng-template [ngTemplateOutlet]="groupByTemplate" />
          }
        </div>
        @if ((drilldownEvents$ | async)?.length) {
          <dpa-simple-drilldown-navigator
            (clearDrilldown)="clearDrilldown()"
            (popDrilldown)="popDrilldown()"
            class="simple-drilldown"
          />
        }
        @if (isControlBarVisible) {
          <button
            (click)="expandOrCollapseControlBar()"
            class="btn btn-link btn-sm btn-icon m-x0 widget-dropdown-indicator-wrapper"
          >
            <cds-icon
              size="20"
              shape="filter-2"
              class="dropdown-indicator"
            />
          </button>
        }
      </div>
    </div>
    @if (showSubHeader) {
      <div class="card-sub-header">
        @if (subheaderTemplate) {
          <ng-template [ngTemplateOutlet]="subheaderTemplate" />
        }
        <dpa-fluid-height
          [isExpanded]="isControlBarExpanded"
          [showOverflowWhenNotAnimating]="true"
        >
          @if (isControlBarExpanded$ | async) {
            <div class="card-control-bar">
              <dpa-widget-control-bar
                [showDateRangePicker]="showDateRangePicker"
                [widgetId]="widgetId"
                [trend]="chartData"
                [selectedChartTypeOverride]="selectedChartType$ | async"
                [showChartTypeSwitcher]="showChartTypeSwitcher"
                [isCrossCategory]="isWidgetJoinEnabled"
              />
            </div>
          }
        </dpa-fluid-height>
      </div>
    }
  </div>

  <div class="card-block">
    <div class="card-block-header ph-x3">
      @if (cardBlockHeaderTemplate) {
        <ng-template [ngTemplateOutlet]="cardBlockHeaderTemplate" />
      }
    </div>
    @if (chartData?.errorMessage) {
      <dpa-web-error
        [webError]="getWebError()"
        class="web-error m-x4 ph-x4"
      />
    }
    <dpa-standard-chart
      #standardChart
      [showDetailsLink]="true"
      [isRangedData]="isRangedData"
      [loading]="!chartData?.trendDefinition"
      [chartData]="chartData"
      [overlayChartData]="overlayChartData"
      [focusedSeries]="focusedSeries$ | async"
      [activeChartType]="selectedChartType$ | async"
      [showSeriesNames]="showSeriesNames"
      [isPercentageOverride]="isPercentageOverride"
      [stackedTooltip]="stackedTooltip"
      [xAxisLabelOverride]="xAxisLabelOverride"
      [yAxisLabelOverride]="yAxisLabelOverride"
      [customColors]="customColors"
      [noBucketingColor]="noBucketingColor"
      [showAllAvailableLabels]="showAllAvailableLabels"
      [noDataMessage]="noDataMessage"
      [noDataTooltip]="noDataTooltip"
      [rotateXAxisTicks]="rotateXAxisTicks"
      [xAxisLabelFactor]="xAxisLabelFactor"
      [showLabels]="showLabels"
      [showXAxis]="showXAxis"
      [showYAxis]="showYAxis"
      [showXAxisLabel]="showXAxisLabel"
      [showDataLabel]="showDataLabel"
      [showLegend]="showLegend"
      [showOverlayLegendTitle]="showOverlayLegendTitle"
      [yAxisRightLabel]="yAxisRightLabel"
      [referenceLines]="referenceLines"
      [showRefLines]="showRefLines"
      [showRefLabels]="showRefLabels"
      [showTimeline]="showTimeline"
      [chartCurveType]="chartCurveType"
      [tableColumnNames]="tableColumnNames"
      [tableColumnLabelsByName]="tableColumnLabelsByName"
      [tableCellTemplatesByName]="tableCellTemplatesByName"
      [tableSettings]="tableSettings"
      [yScaleMax]="yScaleMax"
      [yScaleMin]="yScaleMin"
      [isInvertMode]="isInvertMode$ | async"
      [rangeFilter]="rangeFilter$ | async"
      [colorSchemas]="colorSchemas$ | async"
      [trimXAxisTicks]="trimXAxisTicks"
      [isCountersClickable]="isCountersClickable"
      [markerTooltipTemplate]="markerTooltipTemplate"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [hideSeriesTooltipSubtotal]="hideSeriesTooltipSubtotal"
      [yAxisTicks]="yAxisTicks"
      [ngClass]="size"
      [markers]="markers"
      [skipSort]="skipSort"
      [showZeroSeries]="showZeroSeries"
      (drilldown)="pushDrilldownEvent($event)"
      (onMarkerClick)="handleMarkerClick($event)"
      class="standard-chart"
    />
    @if (cardBlockFooter) {
      <ng-template [ngTemplateOutlet]="cardBlockFooter" />
    }
  </div>
  @if (customFooterTemplate) {
    <div class="custom-footer">
      <ng-template [ngTemplateOutlet]="customFooterTemplate" />
    </div>
  }
  @if (showCardFooter) {
    <div class="card-footer">
      <div class="view-button-container">
        @if (!hideViewLink) {
          <button
            [attr.aria-label]="'DASHBOARD_ACTIONS.VIEW_DAHSBOARD_DETAILS_ARIA_LABEL' | translate: { name: chartTitle }"
            [disabled]="!chartData || !chartData.trendDefinition || disableViewLink"
            (click)="goToWidgetDetail()"
            type="button"
            class="btn btn-link"
          >
            {{ 'COMMON_ACTIONS.VIEW' | translate }}
          </button>
        }
        <ng-template
          [ngTemplateOutlet]="outerActionsTemplate"
          [ngTemplateOutletContext]="{ widgetId: widgetId }"
        />
      </div>
      <dpa-widget-tag
        [trend]="chartData"
        [maxNumTags]="2"
      />
      <clr-dropdown>
        <button
          clrDropdownTrigger
          [disabled]="!chartData || !chartData.trendDefinition"
          type="button"
          class="btn-sm btn-link ph-0"
        >
          <cds-icon
            [class.disabled]="!chartData || !chartData.trendDefinition"
            shape="ellipsis-horizontal"
            size="24"
          />
        </button>
        <clr-dropdown-menu
          *clrIfOpen
          [clrPosition]="dropdownPosition"
        >
          @if (chartData?.trendDefinition?.automationEnabled && (hasAutomationWritePerm$ | async)) {
            <button
              clrDropdownItem
              [disabled]="!chartData?.trendDefinition?.isCategoryAutomationSupported"
              (click)="automateWidget()"
              data-action-type="AUTOMATE"
              type="button"
            >
              {{ 'COMMON_ACTIONS.AUTOMATE' | translate }}
            </button>
          }
          @if (chartData?.trendDefinition) {
            <button
              clrDropdownItem
              (click)="exportAsCsv()"
              data-action-type="EXPORT_AS_CSV"
              type="button"
            >
              {{ 'COMMON_ACTIONS.EXPORT_AS_CSV' | translate }}
            </button>
          }
          @if (isCopyVisible()) {
            <button
              clrDropdownItem
              (click)="requestCopyToWidget()"
              data-action-type="COPY_TO"
              type="button"
            >
              {{ 'COMMON_ACTIONS.COPY_TO' | translate }}
            </button>
          }
          @if (isWidgetEditThemeAvailable) {
            <button
              clrDropdownItem
              (click)="onEditTheme()"
              data-action-type="EDIT_THEME"
              type="button"
            >
              {{ 'COMMON_ACTIONS.EDIT_THEME' | translate }}
            </button>
          }
          @if (isEditRangeAvailable$ | async) {
            <button
              clrDropdownItem
              (click)="openEditRangeDialog(true)"
              data-action-type="EDIT_RANGES"
              type="button"
            >
              {{ 'WIDGET_CUSTOMIZE.EDIT_RANGES' | translate }}
            </button>
          }
          <ng-template [ngTemplateOutlet]="extraActionsTemplate" />
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  }
</div>

@if (isEditRangeDialogOpen$ | async; as isEditRangeDialogOpen) {
  <dpa-widget-edit-range-dialog
    [dashboardId]="dashboardId"
    [isModalOpen]="isEditRangeDialogOpen"
    [chartData]="chartData"
  />
}
