<div class="lookup-variable-selector-container">
  <div
    #menuOpenerContainer
    (mousedown)="preventDefault($event)"
    class="menu-opener-container"
  >
    @if (hideLabel) {
      <cds-icon
        (click)="openMenuHandler()"
        (keyup.enter)="openMenuHandler()"
        class="menu-opener"
        shape="add-text"
        role="button"
        tabindex="0"
      />
    } @else {
      <button
        (click)="openMenuHandler()"
        class="menu-opener-button"
      >
        <cds-icon
          class="menu-opener"
          shape="add-text"
        />
        {{ 'COMMON_MESSAGES.DYNAMIC_VALUES' | translate }}
      </button>
    }
  </div>
  <div
    #keySelectorWrapper
    class="key-selector-offset"
  >
    <dpa-key-selector
      #keySelector
      [show]="menuOpen"
      [showSearchTextBox]="true"
      [isCrossCategory]="true"
      (onDone)="onDoneHandler($event)"
    />
  </div>
</div>
