<div
  [class.read-only]="readOnly"
  [class.card]="applyCardStyling"
  class="filter-group-root"
>
  <div>
    <ng-template
      [ngTemplateOutlet]="groupTextView || defaultGroupTextView"
      [ngTemplateOutletContext]="{
        readOnly: readOnly,
        ruleGroup: group,
        focusFilterGroupRule: focusFilterGroupRuleBound,
        allColumnsByName: columnsByName || allColumnsByName,
        onFilterClick: onFilterClickBound,
        isFilterGroupEmpty: !hasFilterGroup()
      }"
    />
  </div>
  @if (!readOnly && !isCollapsed) {
    <div
      [@fadeInOut]
      [class.card-block]="applyCardStyling"
      class="rule-group-root"
    >
      <ng-template
        [ngTemplateOutlet]="groupView || defaultGroupView"
        [ngTemplateOutletContext]="{
          readOnly: readOnly,
          ruleGroup: group,
          filterColumnsByName: filterColumnsByName,
          allColumnsByName: columnsByName || allColumnsByName,
          visibleColumnsSortedByName: columnsSortedByName || (visibleColumnsSortedByName$ | async)
        }"
      />
    </div>
  }
</div>

<ng-template
  #defaultGroupTextView
  let-readOnly="readOnly"
  let-ruleGroup="ruleGroup"
  let-focusFilterGroupRule="focusFilterGroupRule"
  let-allColumnsByName="allColumnsByName"
  let-isFilterGroupEmpty="isFilterGroupEmpty"
>
  <div
    [class.detail-view]="readOnly"
    class="filter-group-text-view-container"
  >
    <dpa-filter-group-text-view
      [ruleGroup]="ruleGroup"
      [readOnly]="readOnly"
      [allColumnsByName]="allColumnsByName"
      [showLess]="showLess"
      [showEdit]="isCollapsed"
      [showIncludesAllText]="showIncludesAllText"
      [hideEmptyRules]="hideEmptyRules"
      [isInlineFilter]="showFilterTextInline"
      (edit)="toggleCollapse()"
      (focusFilterGroupRule)="focusFilterGroupRule($event)"
    />
  </div>
</ng-template>

<ng-template
  #defaultGroupView
  let-ruleGroup="ruleGroup"
  let-filterColumnsByName="filterColumnsByName"
  let-allColumnsByName="allColumnsByName"
  let-visibleColumnsSortedByName="visibleColumnsSortedByName"
>
  <dpa-filter-group-default-view
    #filterGroupDefaultView
    [editable]="editable"
    [ruleGroup]="ruleGroup"
    [suggestionCategory]="suggestionCategory"
    [suggestionFilterBys]="suggestionFilterBys"
    [showThreeColumnFilter]="showThreeColumnFilter"
    [alwaysShowKeySelector]="alwaysShowKeySelector"
    [filterColumnsByName]="filterColumnsByName || allColumnsByName"
    [allColumnsByName]="allColumnsByName"
    [showColumnsFromInput]="showColumnsFromInput"
    [isCrossCategory]="isCrossCategory"
    [visibleColumnsSortedByName]="visibleColumnsSortedByName"
    [delimiterSupported]="delimiterSupported"
    (onChange)="onRulesChange($event)"
  />
</ng-template>
