<section class="list-filter-component">
  <form
    [formGroup]="filtersForm"
    class="pt-0"
  >
    @for (filterObj of filters; track filterObj.label; let isLast = $last) {
      <div class="filter-section">
        <span class="section-header mt-x1">{{ filterObj.label | translate }}</span>
        @if (filterObj.filterType === FILTER_SELECTION_TYPE.MULTI) {
          <clr-checkbox-container class="mt-0">
            @for (filter of filterObj.filterControls; track filter.name) {
              <clr-checkbox-wrapper>
                <input
                  clrCheckbox
                  [checked]="filtersForm.get(filter.name).value"
                  [id]="filter.name"
                  (click)="updateForm(filter.name, !filtersForm.get(filter.name).value)"
                  type="checkbox"
                />
                <label [for]="filter.name">
                  {{ filter.label | translate }}
                </label>
              </clr-checkbox-wrapper>
            }
          </clr-checkbox-container>
        } @else {
          <clr-radio-container class="mt-0">
            @for (filter of filterObj.filterControls; track filter.name) {
              <clr-radio-wrapper>
                <input
                  clrRadio
                  [checked]="filtersForm.get(filter.name).value === filterObj.selectedValue"
                  [value]="filtersForm.value"
                  [id]="filter.name"
                  (click)="updateForm(filter.name, filter.value)"
                  type="radio"
                  class="radio-btn"
                />
                <label [for]="filter.name">
                  {{ filter.label | translate }}
                </label>
              </clr-radio-wrapper>
            }
          </clr-radio-container>
        }
      </div>
      <div class="horizontal-line"></div>
    }
  </form>
</section>
