<div
  [ngClass]="isSingleLine() ? 'clr-input-wrapper' : 'clr-textarea-wrapper'"
  class="mt-0"
>
  @if (isSingleLine()) {
    <input
      #input
      [attr.id]="id"
      [placeholder]="placeholderText"
      [formControl]="textFormControl$ | async"
      [attr.required]="required ? 'true' : undefined"
      class="clr-input lookup-variable-input"
    />
  } @else {
    <textarea
      #textArea
      [attr.id]="id"
      [placeholder]="placeholderText"
      [formControl]="textFormControl$ | async"
      [attr.required]="required ? 'true' : undefined"
      class="clr-textarea text-area"
    ></textarea>
  }

  @if (!disabledField) {
    <dpa-lookup-variable-selector
      [lookupVariables]="lookupVariables"
      (onLookupVariableSelect)="onLookupVariableSelect($event)"
    />
  }
</div>
