<clr-control-container>
  <label
    [for]="getFieldId()"
    [class.clr-required-mark]="required"
  >
    <dpa-ellipsis-with-tooltip>
      {{ metadata?.label ?? name }}
    </dpa-ellipsis-with-tooltip>
  </label>
  <dpa-admin-user-selector
    [selectedUsers]="getSelectedUsers()"
    [addAccountDetails]="false"
    [hideLabels]="false"
    [allowCustomItems]="true"
    (selectedUsersChange)="onSelectedUsersChange($event)"
  />
</clr-control-container>
