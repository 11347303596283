<dpa-query-builder
  [ruleGroup]="parentFilterRule.customAttributeRuleGroup"
  [filterTemplate]="filterTemplate"
  [filterActionsTemplate]="filterActionTemplate"
  [getNewRule]="getNewFilterRule"
  [isRuleActive]="isFilterRuleActive"
  [isRuleInValid]="isFilterRuleInvalid"
  [allowNestedRules]="false"
  [maxRules]="MAX_ALLOWED_CUSTOM_ATTRIBUTE_RULES_PER_FILTER"
  (queryChange)="onQueryChange()"
/>

<ng-template
  #filterTemplate
  let-filterRule="rule"
  let-parentRuleGroup="parentRuleGroup"
  let-index="index"
  let-filterRuleContainerNode="ruleContainerNode"
  let-onFilterRuleChange="onRuleChange"
>
  <div
    *ngLet="filterRule && focusedFilterRule !== filterRule && isFilterRuleValid(filterRule) as readMode"
    (focusin)="onFocus(filterRule)"
    (focusout)="onFocusOut(filterRule, $event)"
    class="custom-filter-rule"
  >
    @if (readMode) {
      <div class="clr-col-sm rule-text-wrapper">
        <div class="rule-text">
          <dpa-rule-text
            [rule]="filterRule"
            [allColumnsByName]="allColumnsByName"
            (valuesSelect)="onFilterRuleValuesSelect(filterRule, filterRuleContainerNode)"
          />
        </div>
      </div>
    } @else {
      <dpa-custom-attribute-filter-group-rule
        [parentFilterRule]="parentFilterRule"
        [filterRule]="filterRule"
        [allColumnsByName]="allColumnsByName"
        [suggestionFilterBys]="suggestionFilterBys"
        [suggestionCategory]="suggestionCategory"
        [delimiterSupported]="delimiterSupported"
        (filterRuleChange)="onFilterRuleChange($event, index, parentRuleGroup)"
        tabindex="0"
      />
    }
  </div>
</ng-template>

<ng-template
  #filterActionTemplate
  let-filterRule="rule"
  let-filterRuleContainerNode="ruleContainerNode"
>
  <ng-container>
    @if (filterRule && focusedFilterRule !== filterRule && isFilterRuleValid(filterRule)) {
      <dpa-tooltip
        [attr.aria-label]="'COMMON_ACTIONS.EDIT' | translate"
        [tooltipText]="'COMMON_ACTIONS.EDIT' | translate"
        [focusEnabled]="true"
        (click)="enableFilterRuleEdit(filterRule, filterRuleContainerNode)"
        (keyup.enter)="enableFilterRuleEdit(filterRule, filterRuleContainerNode)"
        role="tooltip"
        class="clickable"
      >
        <cds-icon
          shape="pencil"
          size="14"
        />
      </dpa-tooltip>
    }
  </ng-container>
</ng-template>
