<clr-radio-container clrInline>
  <label
    [for]="getFieldId()"
    [class.clr-required-mark]="required"
  >
    <dpa-ellipsis-with-tooltip>
      {{ metadata?.label ?? name }}
    </dpa-ellipsis-with-tooltip>
  </label>
  @for (enum of enumList; track enum; let enumIndex = $index) {
    <clr-radio-wrapper>
      <input
        clrRadio
        [id]="getFieldId(enumIndex)"
        [value]="enum.value"
        [checked]="formControl?.value === enum.value"
        [name]="name"
        (change)="onRadioChange($event)"
        type="radio"
      />
      <label>
        {{ enum.label }}
      </label>
    </clr-radio-wrapper>
  }
</clr-radio-container>
