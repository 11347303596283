<dpa-slide-over
  *ngLet="hasAutomationWritePerm$ | async as hasAutomationWritePerm"
  [isOpen]="isOpen"
  [isContentScrollable]="false"
  [isContentPadded]="false"
  (isOpenChange)="isOpenChange.emit($event)"
>
  <dpa-slide-over-header
    [showCloseBtn]="false"
    [headerActionsTemplate]="headerActionTemplate"
    [title]="title | translate"
    [isLargeHeader]="true"
    [subtitle]="previewAutomation?.name"
  >
    @if (previewAutomation) {
      <div class="header-wrapper preview">
        <button
          (click)="setPreviewAutomation(undefined)"
          class="btn btn-link btn-icon ph-0 mr-2"
        >
          <cds-icon
            shape="arrow"
            direction="left"
            size="24"
          />
        </button>
        <div class="preview-title">View Quickflows</div>
      </div>
    } @else {
      <div class="header-wrapper">
        <dpa-local-search
          [placeHolderText]="'COMMON_ACTIONS.SEARCH' | translate"
          [iconClass]="'search'"
          (onSearch)="onSearch($event)"
        />
        <button
          [disabled]="!hasAutomationWritePerm"
          (click)="onCreateAutomation()"
          class="btn btn-sm btn-outline"
        >
          {{ createBtnText | translate }}
        </button>
      </div>
    }
  </dpa-slide-over-header>
  <div
    *ngLet="automationSearchResponse$ | async as response"
    class="content-container"
  >
    @if (previewAutomation) {
      <div class="preview-container">
        <dpa-lazy-container
          [componentType]="LazyComponentType.AUTOMATION_PREVIEW"
          [props]="{ automationId: previewAutomation?.id }"
        />
      </div>
    }
    @if (!previewAutomation) {
      <div class="content">
        @if (isLoadingAutomationRequest$ | async) {
          <div class="spinner-container-centered">
            <div class="spinner"></div>
          </div>
        } @else {
          @for (automation of response?.results; track automation.id) {
            <div
              [class.selected]="selectedAutomation?.id === automation?.id"
              (keyup.enter)="selectedAutomation = automation"
              (click)="selectedAutomation = automation"
              class="item"
              tabindex="0"
            >
              <clr-radio-wrapper>
                <input
                  clrRadio
                  [checked]="selectedAutomation?.id === automation?.id"
                  [value]="automation?.id"
                  type="radio"
                  tabindex="0"
                />
              </clr-radio-wrapper>
              <div class="item-content">
                <h5>{{ automation.name }}</h5>
                <p>{{ automation.description }}</p>
              </div>
              <button
                (click)="setPreviewAutomation(automation)"
                class="btn btn-sm btn-link"
              >
                {{ 'COMMON_ACTIONS.PREVIEW' | translate }}
              </button>
            </div>
          } @empty {
            <dpa-empty-state
              [description]="'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate"
              [bgImageClass]="'automation-empty-state'"
            />
          }
        }
      </div>
    }
  </div>
  @if (!previewAutomation) {
    <dpa-slide-over-footer>
      <div
        *ngLet="automationSearchResponse$ | async as response"
        class="footer-wrapper"
      >
        <dpa-data-grid
          [footerMode]="true"
          [enableColumnSelector]="false"
          [loading]="isLoadingAutomationRequest$ | async"
          [response]="response"
          [pageSize]="response?.size"
          (pageChange)="onPageChange($event)"
          (refreshClick)="onRefresh()"
          class="clear-footer"
        />
      </div>
    </dpa-slide-over-footer>
  }
</dpa-slide-over>

<ng-template #headerActionTemplate>
  <div class="justify-content-end">
    <button
      (click)="isOpenChange.emit(false)"
      class="btn btn-secondary"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      [clrLoading]="isSelectBtnLoading"
      [disabled]="!previewAutomation && !selectedAutomation"
      (click)="onSelectAutomation(previewAutomation || selectedAutomation)"
      class="btn btn-primary"
    >
      {{ selectBtnText | translate }}
    </button>
  </div>
</ng-template>
