@if (alertBanner$ | async; as alertBanner) {
  @if (alertBanner.visible) {
    <clr-alert
      dpaAutoFocus
      [clrAlertClosed]="!alertBanner.visible"
      [clrAlertAppLevel]="appLevel"
      [clrAlertType]="alertBanner.alertType"
      [clrAlertClosable]="!alertBanner.autoDismiss"
      [class.page-level]="pageLevel"
      (clrAlertClosedChange)="onClose()"
      role="alert"
      tabindex="0"
    >
      @for (alertItem of alertItems; track alertItem?.message) {
        <clr-alert-item>
          <span
            [innerHTML]="alertItem.message"
            class="alert-text"
          ></span>
          @if (alertItem.actionText) {
            <div class="alert-actions">
              @if (isExternalLink(alertItem.actionLink)) {
                <a
                  [href]="alertItem.actionLink"
                  (click)="onActionClick($event, alertItem.actionLink)"
                  class="alert-action"
                  target="_blank"
                  rel="noopener"
                >
                  {{ alertItem.actionText }}
                </a>
              } @else {
                <button
                  [routerLink]="alertItem.actionLink"
                  (click)="onActionClick($event, alertItem.actionLink)"
                  class="btn alert-action"
                >
                  {{ alertItem.actionText }}
                </button>
              }
            </div>
          }
        </clr-alert-item>
      }
    </clr-alert>
  }
}
