@if (!readMode) {
  <div class="rule-row">
    @if (shouldShowKeySelector()) {
      <div class="clr-col-sm">
        @defer (on timer(10ms)) {
          @if (!showThreeColumnFilter) {
            <dpa-type-ahead
              #leadTypeAhead
              [selectedItem]="getSelectedColumn()"
              [searchableItems]="visibleColumnsSortedByName"
              [customFormatter]="typeAheadformatter"
              [labelFormatter]="typeAheadformatter"
              [placeholderText]="'COMMON_ACTIONS.SELECT_OPERATOR' | translate"
              [keyBy]="getColumnKey"
              (selectedItemChange)="onColumnChange($event)"
            />
          } @else {
            <dpa-key-selector-search
              #keySearch
              [showReset]="false"
              [showColumnsFromInput]="showColumnsFromInput"
              [allColumnsByName]="filterColumnsByName || allColumnsByName"
              [selectedColumn]="getSelectedColumn()"
              [placeholderTextKey]="'COMMON_ACTIONS.SELECT_ATTRIBUTE'"
              [isCrossCategory]="isCrossCategory"
              [selectCustomAttribute]="false"
              (columnChange)="onColumnChange($event)"
            />
          }
        }
      </div>
    } @else {
      @if (visibleColumnsSortedByName.length === 1) {
        <div class="clr-col-sm single-column-label-container">
          <span class="single-column-label">
            {{
              visibleColumnsSortedByName[0]?.attribute.fullyQualifiedName
                | columnLabel: filterColumnsByName || allColumnsByName : columnToggleFilterMap
            }}
            @if (
              visibleColumnsSortedByName[0]?.attribute.fullyQualifiedName
                | isAttributeNormalized: filterColumnsByName || allColumnsByName : columnToggleFilterMap
            ) {
              <span class="label">
                {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
              </span>
            }
            @if (visibleColumnsSortedByName[0]?.attribute.fullyQualifiedName | isAttributeRaw: columnToggleFilterMap) {
              <span class="label">
                {{ 'COMMON_MESSAGES.RAW' | translate }}
              </span>
            }
          </span>
        </div>
      }
    }
    <div class="clr-col-sm">
      @defer (on timer(10ms)) {
        <dpa-type-ahead
          [disabled]="!getSelectedColumn()"
          [selectedItem]="getSelectedCondition(rule)"
          [searchableItems]="getAvailableConditions(rule)"
          [customFormatter]="typeAheadformatter"
          [labelFormatter]="typeAheadformatter"
          [placeholderText]="'COMMON_ACTIONS.SELECT_OPERATOR' | translate"
          [keyBy]="getConditionKey"
          (selectedItemChange)="setConditionValue($event)"
        />
      }
    </div>
    <div class="clr-col-sm">
      @defer (on timer(10ms)) {
        @if (rule?.valueRequired) {
          <dpa-filter-value
            [disabled]="!getSelectedColumn()"
            [filterRule]="rule"
            [column]="getSelectedColumn()"
            [placeholderText]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
            [selectedValue]="rule.data"
            [suggestionFilterBys]="suggestionFilterBys"
            [suggestionCategory]="suggestionCategory"
            [selectedCondition]="getSelectedCondition(rule)"
            [dropdownAlignRight]="true"
            [delimiterSupported]="delimiterSupported"
            (selectedValueChange)="onRuleDataChange($event)"
          />
        }
      }
    </div>
  </div>
}
@if (rule?.isCustomAttributeFilterRule()) {
  <div class="ml-x2">
    <dpa-custom-attribute-filter-group
      [parentFilterRule]="$any(rule)"
      [allColumnsByName]="allColumnsByName"
      [suggestionFilterBys]="suggestionFilterBys"
      [suggestionCategory]="suggestionCategory"
      [delimiterSupported]="delimiterSupported"
      (filterGroupChange)="onRuleChange()"
    />
  </div>
}
