<clr-toggle-container>
  <label
    [for]="getFieldId()"
    [class.clr-required-mark]="required"
  >
    <dpa-ellipsis-with-tooltip>
      {{ metadata?.label ?? name }}
    </dpa-ellipsis-with-tooltip>
  </label>
  <clr-toggle-wrapper>
    <input
      clrToggle
      [id]="getFieldId()"
      [formControl]="formControl"
      type="checkbox"
    />
  </clr-toggle-wrapper>
</clr-toggle-container>
