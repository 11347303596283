@if (node) {
  @switch (node.type) {
    <!-- NODE TYPE OBJECT -->
    @case (NODE_TYPE.OBJECT) {
      @if (node.title) {
        <h6>
          {{ node.title }}
        </h6>
      }
      @for (item of node.properties | keyvalue: originalOrder; track item) {
        <dpa-dynamic-form-node
          [node]="item.value"
          [metadataByAnchor]="metadataByAnchor"
          [dependentNodes]="node.dependencies?.[item.key]?.oneOf"
          [nodeKey]="item.key"
          [required]="node.required?.includes(item.key)"
          [parentForm]="nodeControl ?? parentForm"
          [formValues]="nodeControl && formValues?.[nodeKey] ? formValues?.[nodeKey] : formValues"
          [columnLookupVariables]="columnLookupVariables"
          [isTestMode]="isTestMode"
          [readonly]="readonly"
          [searchableActionsEnabled]="searchableActionsEnabled"
        />
      }
    }
    @case (NODE_TYPE.ARRAY) {
      @if (iterateArray) {
        @if (node.title) {
          <h6>
            {{ node.title }}
          </h6>
        }
        @for (item of itemsArray; track item?.$anchor; let i = $index) {
          <dpa-dynamic-form-node
            [node]="item"
            [metadataByAnchor]="metadataByAnchor"
            [nodeKey]="item.$anchor"
            [required]="node.required?.includes(item.$anchor)"
            [parentFormArray]="nodeControl"
            [formValues]="formValues[nodeKey] ?? formValues"
            [value]="formValues?.[nodeKey]?.[i]"
            [columnLookupVariables]="columnLookupVariables"
            [isTestMode]="isTestMode"
            [readonly]="readonly"
            [searchableActionsEnabled]="searchableActionsEnabled"
          />
        }
      } @else {
        @if (!node.enum || node.enum.length > 1) {
          <dpa-form-field
            [name]="nodeKey"
            [control]="nodeControl"
            [metadata]="nodeMetadata"
            [required]="required"
            [enumList]="nodeEnumList"
            [defaultValue]="node.default"
            [columnLookupVariables]="columnLookupVariables"
            [searchableActionsEnabled]="searchableActionsEnabled"
            [value]="value"
            [isTestMode]="isTestMode"
            [readonly]="readonly"
            [formNodeId]="id"
          />
        }
      }
    }
    <!-- NODE TYPE DEFAULT -->
    @default {
      <ng-container [ngTemplateOutlet]="formField" />
    }
  }
}
<ng-template #formField>
  @if (!node.enum || node.enum.length > 1) {
    <dpa-form-field
      [name]="nodeKey"
      [control]="nodeControl"
      [metadata]="nodeMetadata"
      [required]="required"
      [enumList]="nodeEnumList"
      [defaultValue]="node.default"
      [columnLookupVariables]="columnLookupVariables"
      [searchableActionsEnabled]="searchableActionsEnabled"
      [value]="value"
      [isTestMode]="isTestMode"
      [readonly]="readonly"
      [formNodeId]="id"
    />
  }
</ng-template>

<!-- NODE DEPENDENCIES -->
@if (dependentNode) {
  <dpa-dynamic-form-node
    [node]="dependentNode"
    [metadataByAnchor]="metadataByAnchor"
    [parentForm]="parentForm"
    [formValues]="formValues"
    [columnLookupVariables]="columnLookupVariables"
    [isTestMode]="isTestMode"
    [readonly]="readonly"
    [searchableActionsEnabled]="searchableActionsEnabled"
  />
}
