<dpa-modal
  [modalOpen]="true"
  (onModalClose)="closeModal()"
>
  <dpa-modal-title>{{ 'COMMON_ACTIONS.EDIT_THEME' | translate }}: {{ (activeWidget$ | async)?.name }}</dpa-modal-title>
  <div class="modal-body">
    <dpa-alert-banner [target]="AlertBannerTarget.MODAL" />
    @if (colorSchemas) {
      <div class="pt-x4 color-theme-container">
        <div class="clr-row">
          @for (colorSchema of colorSchemas; track colorSchema?.name; let i = $index) {
            <div class="clr-col-6 color-theme-item mb-x3">
              <dpa-color-picker
                [color]="colorSchema.value"
                [colorGroups]="colorGroups"
                [disableAlphaSlider]="true"
                (onColorPickerChange)="selectColor($event, i)"
              />
              <dpa-ellipsis-with-tooltip class="ml-x3">{{ colorSchema.name }}</dpa-ellipsis-with-tooltip>
            </div>
          }
        </div>
      </div>
    }
  </div>
  <div class="modal-footer">
    <button
      (click)="closeModal()"
      class="btn btn-outline"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      (click)="saveWidgetTheme()"
      class="btn btn-primary"
    >
      {{ 'COMMON_ACTIONS.SAVE' | translate }}
    </button>
  </div>
</dpa-modal>
