<div class="clr-row clr-align-items-center">
  <dpa-local-search
    [placeHolderText]="'COMMON_ACTIONS.SEARCH' | translate"
    (onSearch)="onSearchQueryChange($event)"
    iconClass="search"
    class="clr-col"
  />
  @if (selectedLayout === LayoutType.CARD_VIEW) {
    <dpa-type-ahead
      [selectedItem]="selectedSortOn"
      [placeholderText]="'WIDGET_CUSTOMIZE.WIDGET_CHART_ORDER_BY' | translate"
      [searchableItems]="sortByList"
      [customFormatter]="sortByFormatter"
      [labelFormatter]="sortByFormatter"
      (selectedItemChange)="onSortByChange($event)"
      class="clr-col-lg-2 clr-col-md-3"
    />
  }
</div>
<div class="mt-x4 clr-row clr-align-items-center">
  <dpa-marketplace-filter-tags
    [selectedTags]="selectedTags"
    [facetCounts]="response?.facetCountsByType[FacetType.TAGS]"
    (filterChange)="onFilterTagChange($event)"
    class="clr-col"
  />
  @if (availableLayouts.length > 1) {
    <dpa-multi-layout-switcher
      [updateParams]="false"
      [availableLayouts]="availableLayouts"
      [selectedLayout]="selectedLayout"
      (selectedLayoutChange)="selectedLayout = $event"
    />
  }
</div>
@if (selectedLayout === LayoutType.CARD_VIEW) {
  @if (isLoading$ | async) {
    <div class="spinner-container-centered mt-x4">
      <div class="spinner"></div>
    </div>
  } @else {
    @if (response?.searchResults?.results?.length) {
      <div class="clr-row">
        @for (resource of response?.searchResults.results; track resource?.id) {
          <dpa-card
            [header]="resource?.name"
            [description]="resource?.description"
            [hasCustomLabel]="!!resource.customTagText"
            class="clr-col-xl-4 clr-col-lg-4 clr-col-md-6"
          >
            @if (resource.customTagText) {
              <dpa-custom-tag
                [bgColorClass]="AppConfig.MARKETPLACE_RESOURCE_TAG_BG_COLOR_CLASS"
                [text]="resource.customTagText"
                class="ml-x1"
              />
            }
            <dpa-card-block>
              <dpa-card-block-row>
                <ng-container
                  [ngTemplateOutlet]="tags"
                  [ngTemplateOutletContext]="{ resource }"
                />
              </dpa-card-block-row>
            </dpa-card-block>
            <dpa-card-block>
              @if (resource?.modifiedAt) {
                <dpa-card-block-row>
                  <dpa-marketplace-resource-metadata [resource]="resource" />
                </dpa-card-block-row>
              }
            </dpa-card-block>
            <dpa-card-footer>
              <dpa-card-footer-button>
                <button
                  (click)="onPreviewResource(resource)"
                  class="btn btn-link mr-2"
                >
                  {{ 'COMMON_ACTIONS.PREVIEW' | translate }}
                </button>
                @if (resource.showDeployButton) {
                  <button
                    [disabled]="isDeployDisabledForScriptAndSensor$ | async"
                    (click)="onSelectResource(resource, PendoEventMarketplaceTemplatesSelectedSource.DEPLOY)"
                    class="btn btn-link mr-2"
                  >
                    {{ 'COMMON_ACTIONS.DEPLOY' | translate }}
                  </button>
                }
                @if (resource.showAddToWorkspaceButton) {
                  <button
                    [disabled]="!isAddToWorkspaceEnabled(resource)"
                    (click)="onSelectResource(resource, PendoEventMarketplaceTemplatesSelectedSource.ADD_TO_WORKSPACE)"
                    class="btn btn-link mr-2"
                  >
                    {{ 'MARKETPLACE.ADD_TO_WORKSPACE' | translate }}
                  </button>
                }
                @if (resource.showDownloadButton) {
                  <button
                    (click)="onSelectResource(resource, PendoEventMarketplaceTemplatesSelectedSource.DOWNLOAD)"
                    class="btn btn-link"
                  >
                    {{ 'COMMON_ACTIONS.DOWNLOAD' | translate }}
                  </button>
                }
              </dpa-card-footer-button>
            </dpa-card-footer>
          </dpa-card>
        }
      </div>
    } @else {
      <dpa-no-search-results
        [message]="'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"
        class="no-search-results"
      />
    }
  }
  <dpa-data-grid
    [footerMode]="true"
    [enableColumnSelector]="false"
    [loading]="false"
    [pageSize]="response?.searchResults.size"
    [response]="response?.searchResults"
    [noResultsText]="'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate"
    (pageChange)="onPageChange($event)"
    (refreshClick)="onRefresh()"
  />
} @else {
  <div class="mt-x4">
    <dpa-data-grid
      [loading]="isLoading$ | async"
      [response]="response?.searchResults"
      [sortOns]="request?.sortOns"
      [selectable]="{ single: false, enabled: false }"
      [pageSize]="response?.searchResults.size"
      [columnWidthByKey]="columnWidthByKey"
      [noResultsText]="'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"
      (pageChange)="onPageChange($event)"
      (sortChange)="onSortChange($event)"
      class="valign-cells"
    >
      <div
        dpaDataGridSingleRowAction
        [hidden]="isActionHidden(MarketplaceResourceActionType.ADD_TO_WORKSPACE)"
        [disabled]="isAddToWorkspaceActionDisabled()"
        [label]="'MARKETPLACE.ADD_TO_WORKSPACE' | translate"
        (click)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.ADD_TO_WORKSPACE)"
        (keyup.enter)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.ADD_TO_WORKSPACE)"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [hidden]="isActionHidden(MarketplaceResourceActionType.DEPLOY)"
        [disabled]="isDeployDisabledForScriptAndSensor$ | async"
        [label]="'COMMON_ACTIONS.DEPLOY' | translate"
        (click)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.DEPLOY)"
        (keyup.enter)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.DEPLOY)"
      ></div>
      <div
        dpaDataGridSingleRowAction
        [hidden]="isActionHidden(MarketplaceResourceActionType.DOWNLOAD)"
        [label]="'COMMON_ACTIONS.DOWNLOAD' | translate"
        (click)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.DOWNLOAD)"
        (keyup.enter)="onSelectResource($event, PendoEventMarketplaceTemplatesSelectedSource.DOWNLOAD)"
      ></div>
      <div
        dpaDataGridColumn
        [title]="'COMMON_MESSAGES.NAME' | translate"
        [field]="COLUMN_NAMES.byField.name"
        [sortBy]="COLUMN_NAMES.byName.name"
      >
        <ng-template
          dpaDataGridCell
          let-dataItem
        >
          <a
            (click)="onPreviewResource(dataItem)"
            (keyup.enter)="onPreviewResource(dataItem)"
            class="link-normal"
          >
            {{ dataItem?.name }}
          </a>
          @if (dataItem.customTagText) {
            <dpa-custom-tag
              [bgColorClass]="AppConfig.MARKETPLACE_RESOURCE_TAG_BG_COLOR_CLASS"
              [text]="dataItem.customTagText"
            />
          }
        </ng-template>
      </div>
      @if (showUsageCountColumn) {
        <div
          dpaDataGridColumn
          [title]="'MARKETPLACE.USED' | translate"
          [field]="usageCountSortByField"
          [sortBy]="usageCountSortByField"
        >
          <ng-template
            dpaDataGridCell
            let-dataItem
          >
            {{ dataItem?.usageCount | number }}
          </ng-template>
        </div>
      }
      <div
        dpaDataGridColumn
        [title]="'COMMON_MESSAGES.DESCRIPTION' | translate"
        [field]="COLUMN_NAMES.byField.description"
      >
        <ng-template
          dpaDataGridCell
          let-dataItem
        >
          {{ dataItem?.description }}
        </ng-template>
      </div>
      @if (resourceTypes.length === 1) {
        @if (resourceTypes.includes(MarketplaceResourceType.DASHBOARD_TEMPLATE)) {
          <div
            dpaDataGridColumn
            [title]="'COMMON_MESSAGES.WIDGETS' | translate"
            [field]="COLUMN_NAMES.byField.totalWidgets"
          >
            <ng-template
              dpaDataGridCell
              let-dataItem
            >
              {{ dataItem?.metadataByProperty[MarketplaceResourceProperty.WIDGET_COUNT] }}
            </ng-template>
          </div>
        }
        @if (resourceTypes.includes(MarketplaceResourceType.WIDGET_TEMPLATE)) {
          <div
            dpaDataGridColumn
            [title]="'WIDGET_CUSTOMIZE.WIDGET_CHART_TYPE' | translate"
            [field]="COLUMN_NAMES.byField.chartType"
          >
            <ng-template
              dpaDataGridCell
              let-dataItem
            >
              <dpa-chart-type [chartType]="dataItem?.metadataByProperty[MarketplaceResourceProperty.CHART_TYPE]" />
            </ng-template>
          </div>
        }
      }
      <div
        dpaDataGridColumn
        [title]="'COMMON_MESSAGES.TAGS' | translate"
        [field]="COLUMN_NAMES.byName.tags"
      >
        <ng-template
          dpaDataGridCell
          let-dataItem
        >
          <ng-container
            [ngTemplateOutlet]="tags"
            [ngTemplateOutletContext]="{ resource: dataItem }"
          />
        </ng-template>
      </div>
    </dpa-data-grid>
  </div>
}
<ng-template
  #tags
  let-resource="resource"
>
  @if (resource?.flatTags.length === 1) {
    <span class="label">
      {{ resource?.flatTags[0] }}
    </span>
  } @else {
    <clr-signpost>
      <span clrSignpostTrigger>
        <span class="label clickable">{{ 'WIDGET_TEMPLATE.NUM_OF_TAGS' | translate: { num: resource?.flatTags.length } }}</span>
      </span>
      <clr-signpost-content
        *clrIfOpen
        [clrPosition]="SIGNPOST_POSITION.TOP_MIDDLE"
      >
        @for (tag of resource?.flatTags; track $index) {
          <div>
            <span class="label">{{ tag }}</span>
          </div>
        }
      </clr-signpost-content>
    </clr-signpost>
  }
</ng-template>
