<clr-control-container>
  <label
    [for]="getFieldId()"
    [class.clr-required-mark]="required"
  >
    <dpa-ellipsis-with-tooltip>
      {{ metadata?.label ?? name }}
    </dpa-ellipsis-with-tooltip>
  </label>
  <quill-editor
    dpaRichTextEditorConfig
    [formControl]="formControl"
    [placeholder]="metadata?.placeholderValue"
    (onEditorCreated)="onEditorCreation($event)"
  >
    <div quill-editor-toolbar>
      <dpa-rich-text-editor-toolbar
        [editorInstance]="editorInstance"
        [alwaysShowExtraOptions]="true"
      >
        <span
          rich-text-editor-toolbar-end
          class="pl-x2"
        >
          <dpa-lookup-variable-selector
            [lookupVariables]="lookupVariables"
            [hideLabel]="false"
            (onLookupVariableSelect)="onLookupVariableSelect($event)"
          />
        </span>
      </dpa-rich-text-editor-toolbar>
    </div>
  </quill-editor>
</clr-control-container>
