<div class="filters-container">
  <button
    #filterBtn
    [class.active]="isFilterActive"
    (click)="!!filterTemplate || filters ? toggleFilterStatus() : onToggleAdvancedFilters()"
    class="filter-btn clickable mr-x2"
  >
    <span class="filter-text">{{ 'COMMON_ACTIONS.FILTER' | translate | uppercase }}</span>
    <cds-icon
      [attr.direction]="isFilterActive ? 'up' : 'down'"
      shape="angle"
    />
    @if (filtersList.length) {
      <div class="blue-dot"></div>
    }
  </button>
  @if (isFilterActive) {
    <div
      #filterContainer
      class="list-box"
    >
      @if (filterTemplate) {
        <ng-container *ngTemplateOutlet="filterTemplate" />
      } @else {
        <dpa-filter-dropdown
          [filters]="filters"
          (updateFilters)="onUpdateFilters($event)"
        />
      }
      @if (showAdvancedFilters) {
        <div class="horizontal-line"></div>
        <button
          (click)="onToggleAdvancedFilters()"
          class="btn btn-link ml-x1"
        >
          {{ 'COMMON_MESSAGES.ADVANCED_FILTERS' | translate }}
        </button>
      }
    </div>
  }
  <div class="btn-container">
    @if (showAdvancedFilters && isAdvancedFiltersActive) {
      <div class="advanced-filter-container mr-x1">
        <div
          #advancedFiltersBtn
          (click)="toggleAdvancedFiltersPillDropdown()"
          class="advanced-filter-pill-container clickable"
        >
          <ng-container *ngTemplateOutlet="advancedFiltersPillTemplate" />
          <cds-icon
            [attr.direction]="isAdvancedFiltersPillsExpanded ? 'up' : 'down'"
            shape="angle"
            class="angle-icon mr-x2"
          />
        </div>
        @if (isAdvancedFiltersPillsExpanded) {
          <div
            #advancedFiltersDropdown
            class="list-box advanced-filter-dropdown"
          >
            <ng-container *ngTemplateOutlet="advancedFiltersDropdownTemplate" />
          </div>
        }
      </div>
    }
    @for (filterKey of filtersList; track filterKey; let index = $index) {
      @if (index < maxFiltersCount) {
        <div class="btn-filter mr-x1">
          <div class="btn-text-container">
            <dpa-ellipsis-with-tooltip class="mr-x1">{{ activeFilters[filterKey].label }}</dpa-ellipsis-with-tooltip>
            <button
              (click)="onRemoveFilter(filterKey, activeFilters[filterKey].group)"
              role="button"
              tabindex="0"
              class="pill-btn p-x0"
            >
              <cds-icon
                shape="times"
                class="clickable"
              />
            </button>
          </div>
        </div>
      }
    }
    @if (showMorePill && moreActiveFiltersList.length) {
      <div class="show-more-wrapper">
        <div class="btn-filter mr-x1">
          <div class="btn-text-container">
            <button
              #moreBtn
              (click)="toggleMorePill()"
              role="button"
              tabindex="0"
              class="pill-btn p-x0"
            >
              <div class="more-btn-wrapper">
                <dpa-ellipsis-with-tooltip>{{ '+ ' + moreActiveFiltersList.length }}</dpa-ellipsis-with-tooltip>
                <cds-icon
                  [attr.direction]="isMorePillActive ? 'up' : 'down'"
                  shape="angle"
                  class="angle-icon more-icon"
                />
              </div>
            </button>
          </div>
        </div>
        @if (isMorePillActive) {
          <div
            #moreDropdownContainer
            class="show-more-box pb-x1"
          >
            @for (filterKey of moreActiveFiltersList; track filterKey) {
              <div class="btn-filter more-btn mt-x1">
                <div class="btn-text-container">
                  <dpa-ellipsis-with-tooltip class="mr-x1">{{ activeFilters[filterKey].label }}</dpa-ellipsis-with-tooltip>
                  <button
                    (click)="onRemoveFilter(filterKey, activeFilters[filterKey].group)"
                    role="button"
                    tabindex="0"
                    class="pill-btn p-x0"
                  >
                    <cds-icon
                      shape="times"
                      class="clickable"
                    />
                  </button>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
  @if (filtersList.length) {
    <button
      (click)="onClearAllFilters()"
      class="btn btn-link"
      tabindex="0"
      role="button"
    >
      {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_CLEAR_ALL' | translate }}
    </button>
  } @else {
    <span class="no-filters-text">{{ 'COMMON_MESSAGES.NO_FILTERS_APPLIED' | translate }}</span>
  }
</div>
