<clr-control-container>
  <label
    [for]="getFieldId()"
    [class.clr-required-mark]="required"
  >
    <dpa-ellipsis-with-tooltip>
      {{ metadata?.label ?? name }}
    </dpa-ellipsis-with-tooltip>
  </label>
  <dpa-type-ahead
    [id]="getFieldId()"
    [selectedItem]="selectedItem"
    [searchableItems]="enumList"
    [placeholderText]="metadata?.placeholderValue"
    [customFormatter]="typeAheadFormatter"
    [labelFormatter]="typeAheadFormatter"
    [keyBy]="keyBy"
    (selectedItemChange)="setTypeaheadControlValue($event)"
  />
</clr-control-container>
