<clr-alert
  *ngLet="isExpired$ | async as isExpired"
  [clrAlertAppLevel]="true"
  [clrAlertType]="alertType$ | async"
  [clrAlertClosable]="!isExpired"
  (clrAlertClosedChange)="closeBanner($event)"
>
  <clr-alert-item>
    <span class="alert-text">
      @if (isExpired) {
        {{ 'TRIAL_BANNER.TRIAL_EXPIRED' | translate }}
      }
      @if (isCountingDown$ | async) {
        {{ 'TRIAL_BANNER.FREE_TRIAL_COUNTDOWN' | translate: { count: (expiresDays$ | async) } }}
      }
      @if (isDeclined$ | async) {
        {{ 'TRIAL_BANNER.TRIAL_DECLINED' | translate: { count: TRIAL_DAYS } }}
      }
      @if (isTrialStarted$ | async) {
        {{ 'TRIAL_BANNER.TRIAL_STARTED' | translate: { count: TRIAL_DAYS } }}
      }
    </span>
    <div class="alert-actions">
      @if (isExpired) {
        <a
          [href]="contactSalesUrl$ | async"
          target="_blank"
          rel="noopener"
          class="btn alert-action"
        >
          {{ 'COMMON_MESSAGES.CONTACT_SALES' | translate }}
        </a>
        <button
          (click)="dismissExpiredBanner()"
          class="btn alert-action alert-dismiss"
        >
          {{ 'TRIAL_BANNER.IAM_NOT_INTERESTED' | translate }}
        </button>
      }
      @if (isDeclined$ | async) {
        <button
          (click)="activateTrial()"
          class="btn alert-action"
        >
          {{ 'TRIAL_BANNER.ACTIVATE_TRIAL' | translate }}
        </button>
      }
    </div>
  </clr-alert-item>
</clr-alert>
