<ng-container *ngLet="userGuideUrl$ | async as userGuide">
  @if (connectorModalState$ | async; as modalState) {
    @switch (modalState.type) {
      @case (ConnectorModalType.ADD) {
        <dpa-connector-add-modal
          [isLoading]="modalState.isConfirmLoading"
          [connectorConfigSchema]="modalState.connectorConfigSchema"
          [userGuideUrl]="userGuide"
          (create)="onCreate($event)"
          (modalClose)="closeModal()"
        />
      }
      @case (ConnectorModalType.EDIT_CONNECTOR) {
        <dpa-connector-rename-modal
          [isLoading]="modalState.isConfirmLoading"
          [connector]="modalState.connector"
          (rename)="onRename(modalState.connector.id, $event)"
          (modalClose)="closeModal()"
        />
      }
      @case (ConnectorModalType.EDIT_CONFIG) {
        <dpa-connector-edit-modal
          [isLoading]="modalState.isConfirmLoading"
          [connectorConfigSchema]="modalState.connectorConfigSchema"
          [connector]="modalState.connector"
          [connectorConfig]="modalState.connectorConfig"
          (edit)="onEdit(modalState.connector.configId, $event)"
          (modalClose)="closeModal()"
        />
      }
      @case (ConnectorModalType.AUTHORIZE) {
        <dpa-connector-authorize-modal
          [isLoading]="modalState.isConfirmLoading"
          [connectorConfigSchema]="modalState.connectorConfigSchema"
          [connector]="modalState.connector"
          (authorize)="onAuthorize(modalState.connector.id, $event)"
          (modalClose)="closeModal()"
        />
      }
      @case (ConnectorModalType.DELETE) {
        <dpa-connector-delete-modal
          [isLoading]="modalState.isConfirmLoading"
          [connector]="modalState.connector"
          [connectorUsageCount]="modalState.connectorUsageCount"
          (delete)="onDelete(modalState.connector)"
          (viewWorkflows)="onViewWorkflows(modalState.connector)"
          (modalClose)="closeModal()"
        />
      }
    }
  }
</ng-container>
