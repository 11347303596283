<clr-alert
  [clrAlertType]="'warning'"
  [clrAlertClosable]="false"
>
  <clr-alert-item>
    <span class="alert-text">
      {{ alertText }}
    </span>
    @if (isTrialNotActive$ | async) {
      <div class="alert-actions">
        @if (isTrialExpired$ | async) {
          <a
            [href]="contactSalesUrl$ | async"
            target="_blank"
            rel="noopener"
            class="alert-action"
          >
            {{ 'TRIAL.CONTACT_SALES' | translate }}
          </a>
        } @else {
          <a
            (click)="onActivateTrialBtnClick()"
            (keyup.enter)="onActivateTrialBtnClick()"
            class="alert-action"
            target="_blank"
            rel="noopener"
          >
            {{ 'TRIAL.START_TRIAL' | translate }}
          </a>
          <a
            [href]="contactSalesUrl$ | async"
            target="_blank"
            rel="noopener"
            class="alert-action"
          >
            {{ 'TRIAL.UPGRADE' | translate }}
          </a>
        }
      </div>
    }
  </clr-alert-item>
</clr-alert>
