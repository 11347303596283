<dpa-local-search
  #localSearch
  [showIcon]="false"
  [initValue]="getSelectedColumnFullLabel()"
  [inputValue]="searchString"
  [placeHolderText]="placeholderTextKey | translate"
  [showReset]="showReset"
  [showTooltip]="true"
  (onSearch)="setSearchString($event)"
  (onFocus)="showKeySelector($event)"
  (onClick)="showKeySelector($event)"
  (onKeydown)="onKeydownEvent($event)"
  iconClass="search"
/>
@if (show) {
  <dpa-key-selector
    #filterKeySelector
    [show]="true"
    [showColumnsFromInput]="showColumnsFromInput"
    [allColumns]="allColumns"
    [keydownKey]="keydownKey"
    [keydownTrigger]="keydownTrigger"
    [currentKey]="getSelectedColumn()"
    [searchString]="searchString"
    [isCrossCategory]="isCrossCategory"
    [columnToggleFilterMap]="columnToggleFilterMap"
    [selectCustomAttribute]="selectCustomAttribute"
    (onDone)="onColumnChange($event)"
  />
}
