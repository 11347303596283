@if (disableLink) {
  <div>
    {{ dataItem[dataGridColumn.value] }}
  </div>
} @else {
  <a
    (click)="goToDeviceDetailPage(dataItem)"
    (keyup.enter)="goToDeviceDetailPage(dataItem)"
    class="link-normal"
  >
    {{ dataItem[dataGridColumn.value] }}
  </a>
}
