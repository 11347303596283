<clr-input-container>
  <label
    [for]="getFieldId()"
    [class.clr-required-mark]="required"
  >
    <dpa-ellipsis-with-tooltip>
      {{ metadata?.label ?? name }}
    </dpa-ellipsis-with-tooltip>
  </label>
  <input
    clrInput
    [id]="getFieldId()"
    [type]="MetaFormConfig.inputTypeByPresentationType[metadata?.presentationType]"
    [attr.placeholder]="metadata?.placeholderValue || 'https://'"
    [formControl]="formControl"
  />
  @if (metadata?.note) {
    <clr-control-helper>
      {{ metadata?.note }}
    </clr-control-helper>
  }
</clr-input-container>
