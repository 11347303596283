@if (multiUserSelect) {
  <dpa-type-ahead-multi
    #adminUserSelectorTypeAhead
    [selectedItems]="selectedUsers"
    [searchableItems]="adminUsers$ | async"
    [searchFormatter]="searchFormatter"
    [labelFormatter]="labelFormatter"
    [isItemDisabled]="isDisabled"
    [hideLabels]="hideLabels"
    [keyBy]="keyBy"
    [placeholderText]="'COMMON_ACTIONS.SELECT_USERS' | translate"
    [useCheckboxes]="false"
    [inputType]="'text'"
    [createCustomItem]="allowCustomItems ? wrapEmail : undefined"
    [tooltip]="getDisabledTooltipText"
    [isSuggestionLoading]="isLoading$ | async"
    [customRowTemplate]="customRowTemplate"
    [skipFuzzyFilter]="true"
    [includeSelectedInResults]="true"
    [disabled]="disabled"
    (onQueryChange)="onQueryChange($event)"
    (selectedItemsChange)="selectedUsersChange.emit($event)"
  />
  @if (hideLabels) {
    @if (!isSelectedUserLoading) {
      @if (selectedUsers.length) {
        <div class="mt-x1 text-right summary">
          <a
            (click)="removeAllUsers()"
            (keyup.enter)="removeAllUsers()"
            class="label clickable"
          >
            <span
              [innerHTML]="'COMMON_MESSAGES.COUNT_USERS_SELECTED' | translate: { count: selectedUsers.length }"
              class="mr-x2"
            ></span>
            <cds-icon shape="window-close" />
          </a>
        </div>
        <div
          dpaScrollbars
          [useFlexbox]="true"
          class="selected-users mt-x1"
        >
          @for (user of selectedUsers; track user?.id; let i = $index) {
            <div class="clr-row m-x0 p-x1 clr-flex-items-sm-middle admin-row">
              <div class="clr-col-sm-1 pl-0">
                <span class="initials">
                  <cds-icon
                    class="user"
                    shape="user"
                  />
                </span>
              </div>
              <div
                [ngClass]="customSelectedRowTemplate ? 'clr-col-sm-8' : 'clr-col-sm-9'"
                class="clr-flex-column pl-0"
              >
                <dpa-ellipsis-with-tooltip class="user-info">
                  {{ user?.userInfo }}
                </dpa-ellipsis-with-tooltip>
                <div class="p5 mt-0 user-details">
                  @if (user?.fullName) {
                    <span>{{ 'COMMON_MESSAGES.NAME' | translate }}: {{ user.fullName }}</span>
                  }
                  <span class="dot">&middot;</span>
                  <span>{{ 'COMMON_MESSAGES.DIRECTORY' | translate }}: {{ user?.directoryType18nName | translate }}</span>
                </div>
              </div>
              @if (customSelectedRowTemplate) {
                <div class="clr-col-sm-2">
                  <ng-container
                    [ngTemplateOutlet]="customSelectedRowTemplate"
                    [ngTemplateOutletContext]="{
                      user: user,
                      menuPosition: i > 1 ? CLARITY_TOOLTIP_POSITION.TOP_RIGHT : CLARITY_TOOLTIP_POSITION.BOTTOM_RIGHT
                    }"
                  />
                </div>
              }
              <div class="clr-col-sm-1 pr-0 text-right">
                <a
                  (click)="removeUser(user)"
                  (keyup.enter)="removeUser(user)"
                  class="remove-user"
                >
                  <cds-icon
                    shape="times"
                    size="24"
                  />
                </a>
              </div>
            </div>
          }
        </div>
      } @else {
        <div class="no-users">
          {{ 'COMMON_MESSAGES.NO_USERS_SELECTED' | translate }}
        </div>
      }
    } @else {
      <div class="spinner-container mt-x2">
        <span class="spinner spinner-md"></span>
      </div>
    }
  }
} @else {
  <dpa-type-ahead
    #adminUserSelectorTypeAhead
    [searchableItems]="adminUsers$ | async"
    [labelFormatter]="labelFormatter"
    [isItemDisabled]="isDisabled"
    [keyBy]="keyBy"
    [placeholderText]="'COMMON_ACTIONS.SELECT_USERS' | translate"
    [tooltip]="getDisabledTooltipText"
    [isSuggestionLoading]="isLoading$ | async"
    [customRowTemplate]="customRowTemplate"
    [skipFuzzyFilter]="true"
    (onQueryChange)="onQueryChange($event)"
    (selectedItemChange)="selectedUserChange.emit($event)"
  />
}
<ng-template
  #customRowTemplate
  let-filteredResult="filteredResult"
  let-inFocus="inFocus"
  let-isItemSelected="isItemSelected"
>
  <div class="clr-row p-x1 clr-flex-items-sm-middle admin-row">
    <input
      [checked]="isItemSelected(filteredResult.original)"
      [type]="multiUserSelect ? 'checkbox' : 'radio'"
      class="mh-x2"
    />
    <div
      [ngClass]="policyTemplate ? 'clr-col-9' : 'clr-col-10'"
      class="clr-flex-column"
    >
      <div class="user-name">
        <dpa-ellipsis-with-tooltip class="user-info">
          {{ filteredResult.original.userInfo }}
        </dpa-ellipsis-with-tooltip>
      </div>
      <div class="p5 mt-0 user-details">
        @if (filteredResult.original.fullName) {
          <span>{{ 'COMMON_MESSAGES.NAME' | translate }}: {{ filteredResult.original.fullName }}</span>
        }
        <span class="dot">&middot;</span>
        <span>{{ 'COMMON_MESSAGES.DIRECTORY' | translate }}: {{ filteredResult.original.directoryType18nName | translate }}</span>
      </div>
    </div>
    @if (policyTemplate) {
      <div class="clr-col-2">
        <ng-container
          [ngTemplateOutlet]="policyTemplate"
          [ngTemplateOutletContext]="{ user: filteredResult.original }"
        />
      </div>
    }
  </div>
</ng-template>
