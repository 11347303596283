@if (isPreviewTag$ | async) {
  <div
    [class.expired]="expired"
    class="banner-wrapper"
  >
    <span class="banner-text">
      {{ 'COMMON_MESSAGES.TRIAL' | translate }}
    </span>
  </div>
}
