@if (searchableActionsEnabled) {
  <dpa-lookup-form
    [field]="field"
    [formControlValue]="formControl?.value"
    [lookupMap]="lookupMap$ | async"
    [lookupMapError]="lookupMapError$ | async"
    [lookupMapLoading]="lookupMapLoading$ | async"
    [nestedLookups]="nestedLookups"
    (lookupChoiceChange)="onLookupChoiceClick($event)"
    (customValueChoiceChange)="onCustomValueChoiceClick()"
    (onRequestLookup)="onRequestFieldLookup($event)"
    (onSetFieldValueFromLookup)="setFieldValueFromLookup($event, field.name)"
  />
}
@if (showCustomInput) {
  <dpa-form-field
    [name]="name"
    [control]="formControl"
    [metadata]="customMetadata"
    [required]="required"
    [enumList]="enumList"
    [defaultValue]="defaultValue"
    [columnLookupVariables]="lookupVariables"
    [searchableActionsEnabled]="searchableActionsEnabled"
  />
}
