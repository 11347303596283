<div class="card mt-0">
  <div class="card-header usage-header pb-0">
    <h3 class="card-title">
      {{ heading }}
    </h3>
  </div>
  <div class="card-block usage-row p-x0">
    @if (!isLoading) {
      @if (used !== undefined && total !== undefined) {
        <div class="usage-item-1">
          <dpa-standard-chart
            [chartData]="trend"
            [showLabels]="false"
            [stackedTooltip]="donutTooltip"
            [activeChartType]="CHART_TYPE.DONUT"
            [customColors]="usageColors"
            [totalHeaderValue]="used"
            [totalHeaderKey]="'COMMON_MESSAGES.USED' | translate"
            [totalHeaderClass]="'overlay-total-big'"
            class="usage-chart"
          />
        </div>
        <div class="usage-item-2">
          <div class="usage-item-comparision">{{ used | number }} / {{ total | number }}</div>
          <div class="usage-item-remaining">
            @if (total - used >= 0) {
              {{ 'COMMON_MESSAGES.N_REMAINING' | translate: { n: total - used } }}
            } @else {
              {{ overUsageText }}
            }
          </div>
        </div>
      } @else {
        <div class="pt-x6 pb-x6">
          <dpa-no-search-results [message]="'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate" />
        </div>
      }
    } @else {
      <dpa-timeout-loader
        [skeletonClassName]="SkeletonClassName.RESULTS_DATA"
        class="timeout-loader"
      />
    }
  </div>
  @if (showFooter && footerText) {
    <div class="card-footer text-center pt-0">
      {{ footerText }}
      @if (footerTooltipText) {
        <dpa-tooltip
          [tooltipText]="footerTooltipText"
          role="tooltip"
        >
          <cds-icon
            shape="info-standard"
            size="20"
            class="title-tip"
          />
        </dpa-tooltip>
      }
    </div>
  }
</div>
<ng-template
  #donutTooltip
  let-ngxModel="ngxModel"
  let-tooltip="tooltip"
>
  <div class="tool-tip-container">
    @for (filter of tooltip.filters; track filter) {
      <div class="key-value-pair">
        <span class="key-label">{{ filter.value }}:</span>
        <span class="value-label">
          {{ ngxModel.value }}
        </span>
      </div>
    }
  </div>
</ng-template>
