@if (tagNames.length && (tagNames.length === 1 || tagNames.length < maxNumTags)) {
  @for (key of tagNames; track key) {
    <!-- possible key for getTooltipText(key)
    WIDGET_TEMPLATE.HISTORICAL
    WIDGET_TEMPLATE.SNAPSHOT
    -->
    <dpa-tooltip-tag
      [labelText]="tagLabelsByName[key]"
      [tooltipText]="getTooltipText(key)"
    />
  }
} @else {
  @if (tagNames.length) {
    <clr-dropdown>
      <button
        clrDropdownTrigger
        class="ph-0"
      >
        <span class="label">
          {{ 'WIDGET_TEMPLATE.NUM_OF_TAGS' | translate: { num: tagNames.length } }}
        </span>
      </button>
      <clr-dropdown-menu
        *clrIfOpen
        [clrPosition]="isMinimized ? CLARITY_TOOLTIP_POSITION.BOTTOM_RIGHT : CLARITY_TOOLTIP_POSITION.TOP_RIGHT"
      >
        @for (key of tagNames; track key) {
          <span>
            <!-- possible key for getTooltipText(key)
            WIDGET_TEMPLATE.HISTORICAL
            WIDGET_TEMPLATE.SNAPSHOT
            -->
            <dpa-tooltip-tag
              clrDropdownItem
              [labelText]="tagLabelsByName[key]"
              [tooltipText]="getTooltipText(key)"
            />
          </span>
        }
      </clr-dropdown-menu>
    </clr-dropdown>
  }
}
