<div class="card">
  <div class="card-block ph-x2">
    <h5 class="card-title pl-x2">
      {{ title }}
      @if (tooltip) {
        <dpa-tooltip [tooltipText]="tooltip">
          <cds-icon
            shape="info-circle"
            size="24"
          />
        </dpa-tooltip>
      }
    </h5>
    <div class="card-text">
      <ng-content />
    </div>
  </div>
  <ng-content select="dpa-side-panel-main-body-card-block" />
  @if (showFooterLinkToExpandedBody) {
    <div class="card-footer">
      <button
        (click)="toggleSidePanel(true)"
        class="btn btn-sm btn-link"
      >
        {{ 'COMMON_ACTIONS.VIEW' | translate }}
      </button>
      <ng-content select="dpa-side-panel-main-body-card-footer" />
    </div>
  }
</div>
