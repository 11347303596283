<clr-textarea-container>
  <label
    [for]="getFieldId()"
    [class.clr-required-mark]="required"
  >
    <dpa-ellipsis-with-tooltip>
      {{ metadata?.label ?? name }}
    </dpa-ellipsis-with-tooltip>
  </label>
  <textarea
    clrTextarea
    [id]="getFieldId()"
    [attr.placeholder]="metadata?.placeholderValue"
    [attr.required]="required"
    [formControl]="formControl"
  ></textarea>
</clr-textarea-container>
