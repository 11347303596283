@switch (resource?.resourceType) {
  @case (MarketplaceResourceType.WIDGET_TEMPLATE) {
    <div>
      <dpa-chart-type
        [chartType]="metadata.chartType"
        [trendMode]="metadata.trendMode"
      />
      <ng-container
        [ngTemplateOutlet]="usageCountTemplate"
        [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
      />
    </div>
  }
  @case (MarketplaceResourceType.REPORT_TEMPLATE) {
    <div>
      <dpa-from-now
        [formatString]="'CONNECTIONS.MODIFIED_WHEN'"
        [timestamp]="resource?.modifiedAt"
      />
      @if (metadata.reportType) {
        <div>
          {{ 'COMMON_MESSAGES.DATA_TYPE' | translate }}:
          {{ 'REPORT_BUILDER.REPORT_TYPE_' + metadata.reportType | translate }}
        </div>
      }
      <ng-container
        [ngTemplateOutlet]="usageCountTemplate"
        [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
      />
    </div>
  }
  @case (MarketplaceResourceType.AUTOMATION_TEMPLATE) {
    <div>
      <dpa-from-now
        [formatString]="'CONNECTIONS.MODIFIED_WHEN'"
        [timestamp]="resource?.modifiedAt"
      />
      <ng-container
        [ngTemplateOutlet]="usageCountTemplate"
        [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
      />
    </div>
  }
  @case (MarketplaceResourceType.SCRIPT_TEMPLATE) {
    <div>
      <div>{{ 'COMMON_MESSAGES.LANGUAGE_NAME' | translate: { language: metadata.language } }}</div>
      <div>{{ 'COMMON_MESSAGES.PLATFORM_NAME' | translate: { name: metadata.platform } }}</div>
      <ng-container
        [ngTemplateOutlet]="usageCountTemplate"
        [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
      />
    </div>
  }
  @case (MarketplaceResourceType.SENSOR_TEMPLATE) {
    <div>
      <div>{{ 'COMMON_MESSAGES.LANGUAGE_NAME' | translate: { language: metadata.language } }}</div>
      <div>{{ 'COMMON_MESSAGES.PLATFORM_NAME' | translate: { name: metadata.platform } }}</div>
      <ng-container
        [ngTemplateOutlet]="usageCountTemplate"
        [ngTemplateOutletContext]="{ usageCount: resource.usageCount }"
      />
    </div>
  }
  @case (MarketplaceResourceType.UEM_FREESTYLE_WORKFLOW_TEMPLATE) {
    <div>
      <div>{{ 'COMMON_MESSAGES.PLATFORM_NAME' | translate: { name: metadata.platform } }}</div>
    </div>
  }
}
<ng-template
  #usageCountTemplate
  let-usageCount="usageCount"
>
  @if (usageCount) {
    <div [innerHTML]="'MARKETPLACE.USED_COUNT_MESSAGE' | translate: { count: usageCount | number }"></div>
  }
</ng-template>
