<button
  #expandableSearch
  [class.clickable]="!isSearchActive"
  [class.active]="isSearchActive"
  (click)="onSearchClick()"
  class="search-container clickable"
  tabindex="0"
  role="button"
>
  <cds-icon
    shape="search"
    size="16"
  />
  @if (isSearchActive) {
    <dpa-local-search
      [inputValue]="query"
      [placeHolderText]="placeholderText ?? ('COMMON_ACTIONS.SEARCH' | translate)"
      [showIcon]="false"
      [showReset]="true"
      (onSearch)="onSearch($event)"
      class="search-input"
    />
  }
</button>
