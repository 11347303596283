<dpa-modal
  [modalOpen]="true"
  [modalSize]="ModalSize.LG"
  (onModalClose)="modalClose.emit()"
>
  <dpa-modal-title>
    <span class="mt-x0">
      {{ 'CONNECTIONS.ADD_NEW_WORKFLOW_CONNECTOR' | translate }}
    </span>
  </dpa-modal-title>

  <div class="modal-body">
    <clr-tabs>
      <clr-tab>
        <button clrTabLink>{{ 'CONNECTIONS.MANAGED_CONNECTORS' | translate }}</button>
        <ng-template
          [clrIfActive]="selectedTab === ADD_CONNECTOR_TAB.MANAGED_CONNECTORS"
          (clrIfActiveChange)="selectTab(ADD_CONNECTOR_TAB.MANAGED_CONNECTORS)"
        >
          <clr-tab-content>
            <dpa-alert-banner
              [target]="ALERT_BANNER_TARGET.MODAL"
              class="pb-x2"
            />
            @if (isManagedConnectorsLoading$ | async) {
              <div class="spinner-container">
                <div class="spinner spinner-lg"></div>
              </div>
            } @else {
              @if (managedConnectorsSearchResponse?.total) {
                <div class="list-wrapper top-border">
                  <div class="list-container divider">
                    <div class="list-container-inner">
                      <div class="group-list mr-x4">
                        @for (connector of managedConnectorsSearchResponse?.results; track connector?.id) {
                          <dpa-list-item
                            [isCompact]="true"
                            [isBoxed]="false"
                            [isHoverable]="true"
                            [isSelected]="selectedManagedConnector?.name === connector.name"
                            [label]="connector.name"
                            [hasArrow]="false"
                            [iconTemplate]="iconTemplate"
                            (itemClick)="onSelectionChange(connector)"
                          />
                          <ng-template #iconTemplate>
                            <div
                              [attr.data-service-type]="connector?.integration"
                              [style.background-image]="connector?.iconBackgroundImage"
                              class="dpa-icon automation-action-icon medium"
                            ></div>
                          </ng-template>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="details-view">
                    <ng-template [ngTemplateOutlet]="detailsView" />
                  </div>
                </div>
              } @else {
                <dpa-empty-state
                  [description]="'CONNECTIONS.THERE_ARE_NO_CONNECTORS_AVAILABLE_TO_SET_UP' | translate"
                  [showAction]="false"
                  bgImageClass="bg-image robot-inspect no-results-robot"
                />
              }
            }
            <ng-template #detailsView>
              <div class="header-container pl-x4">
                <div
                  [attr.data-service-type]="selectedManagedConnector?.integration"
                  [style.background-image]="selectedManagedConnector?.iconBackgroundImage"
                  class="dpa-icon automation-action-icon medium"
                ></div>
                <h4 class="pl-x2">
                  {{ selectedManagedConnector?.name }}
                </h4>
              </div>
              <button
                [disabled]="!selectedManagedConnector"
                (click)="onSetUp()"
                type="button"
                class="btn btn-primary m-x4"
              >
                {{ 'COMMON_ACTIONS.SET_UP' | translate }}
                {{ selectedManagedConnector?.name }}
              </button>
              <div class="pl-x4">
                {{ selectedManagedConnector?.description }}
              </div>
              <div class="pl-x2">
                <a
                  [href]="selectedManagedConnector.setupInfoUrl"
                  target="_blank"
                  rel="noopener"
                  class="btn btn-link btn-sm doc-link"
                >
                  <cds-icon shape="pop-out" />
                  {{ 'COMMON_ACTIONS.LEARN_MORE' | translate }}
                </a>
              </div>
            </ng-template>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      @if (isCustomConnectorEnabled$ | async) {
        <clr-tab>
          <button clrTabLink>{{ 'CONNECTIONS.CUSTOM_CONNECTORS' | translate }}</button>
          <ng-template
            [clrIfActive]="selectedTab === ADD_CONNECTOR_TAB.CUSTOM_CONNECTORS"
            (clrIfActiveChange)="selectTab(ADD_CONNECTOR_TAB.CUSTOM_CONNECTORS)"
          >
            <clr-tab-content>
              <dpa-connector-setup-custom-connector
                [userGuideUrl]="userGuideUrl"
                [isLoading]="isLoading"
                [connectorConfigSchema]="connectorConfigSchema"
                (create)="onCreate($event)"
              />
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      }
    </clr-tabs>
  </div>
</dpa-modal>
