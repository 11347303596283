<div class="trigger-if">
  <span
    [attr.data-service-type]="automation?.integration || automationTemplate?.integration | uppercase"
    class="trigger-action connection-icon mr-x1"
  ></span>
  <cds-icon
    shape="arrow"
    direction="right"
    size="22"
    class="mr-x1"
  />
  @if (automation?.iconLinks?.length > 0) {
    @for (iconLink of automation?.iconLinks; track iconLink) {
      <div class="badge-container">
        <span
          [style.background-image]="iconLink | urlProperty"
          class="trigger-action connection-icon mr-x1"
        ></span>
      </div>
    }
  } @else {
    <dpa-tooltip [tooltipText]="'WORKFLOWS.CANNOT_ENABLE_REASON' | translate">
      <cds-icon
        shape="ban"
        solid
        status="danger"
        size="28"
      />
    </dpa-tooltip>
  }
</div>
