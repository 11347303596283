<dpa-slide-over
  [isOpen]="isVisible$ | async"
  (isOpenChange)="onHidePreview()"
>
  <dpa-slide-over-header [title]="'MARKETPLACE.TEMPLATE_PREVIEW' | translate" />
  @if (resource$ | async; as resource) {
    <ng-container
      [ngTemplateOutlet]="previewTemplateByResourceType[resource?.resourceType]"
      [ngTemplateOutletContext]="{ resource }"
    />
  }
  <dpa-slide-over-footer>
    <div class="footer-wrapper">
      <div class="previous">
        @if (previousResource$ | async; as prevResource) {
          <button
            (click)="previewPrevResource(prevResource)"
            class="btn btn-link ph-0"
          >
            <cds-icon
              shape="angle"
              direction="left"
            />
            <div class="btn-text">{{ prevResource.name }}</div>
          </button>
        }
      </div>
      <div class="next">
        @if (nextResource$ | async; as nextResource) {
          <button
            (click)="previewNextResource(nextResource)"
            class="btn btn-link btn-icon ph-0"
          >
            <div class="btn-text">{{ nextResource.name }}</div>
            <cds-icon
              shape="angle"
              direction="right"
            />
          </button>
        }
      </div>
    </div>
  </dpa-slide-over-footer>
</dpa-slide-over>
