<form [formGroup]="formGroup">
  @for (formGroupName of formGroupNames; track formGroupName) {
    <h3>
      {{ fieldsBySection[formGroupName].label }}
    </h3>
    <dpa-fields-meta-form
      [columnLookupVariables]="columnLookupVariables"
      [isEditMode]="isEditMode"
      [formGroup]="getFormGroup(formGroupName)"
      [metaForm]="fieldsBySection[formGroupName]"
      [settings]="(settings && settings[formGroupName]) || {}"
      [searchableActionsEnabled]="searchableActionsEnabled"
      [lookupMap]="lookupMap"
      [lookupMapLoading]="lookupMapLoading"
      [nestedLookups]="nestedLookups"
      (formGroupChange)="onFormChange($event, formGroupName)"
      (lookupChoiceChange)="onLookupChoiceChange($event)"
      (onRequestLookup)="onRequestFieldLookup($event)"
    />
  }
</form>
