<clr-input-container>
  <label
    [for]="getFieldId()"
    [class.clr-required-mark]="required"
  >
    <dpa-ellipsis-with-tooltip>
      {{ metadata?.label ?? name }}
    </dpa-ellipsis-with-tooltip>
  </label>
  <input
    clrInput
    [id]="getFieldId()"
    [type]="MetaFormConfig.inputTypeByPresentationType[metadata?.presentationType]"
    [attr.placeholder]="metadata?.placeholderValue"
    [formControl]="formControl"
  />
  <clr-control-error *clrIfError="'minlength'; error as minLengthError">
    {{ 'ERROR_MESSAGES.MIN_LENGTH' | translate: { n: minLengthError.requiredLength } }}
  </clr-control-error>
  <clr-control-error *clrIfError="'maxlength'; error as maxLengthError">
    {{ 'ERROR_MESSAGES.MAX_LENGTH' | translate: { n: maxLengthError.requiredLength } }}
  </clr-control-error>
  <clr-control-error *clrIfError="'min'; error as minError">
    {{ 'ERROR_MESSAGES.MIN_VALUE' | translate: { n: minError.min } }}
  </clr-control-error>
  <clr-control-error *clrIfError="'max'; error as maxError">
    {{ 'ERROR_MESSAGES.MAX_VALUE' | translate: { n: maxError.max } }}
  </clr-control-error>
</clr-input-container>
