<div class="legend-header">
  @if (!isExploreMode) {
    <div class="legend-group-by">
      {{ ngxChart?.colorizedAttributeLabel }}
    </div>
  } @else {
    <dpa-key-selector-search
      [showReset]="false"
      [showColumnsFromInput]="true"
      [allColumns]="availableColumns"
      [selectedColumn]="colorizedColumn"
      [placeholderTextKey]="'DASHBOARD_ACTIONS.SELECT_A_GROUP_BY'"
      (columnChange)="selectedColumnChange.emit($event)"
      class="chart-group-type-ahead"
    />
  }
  <div class="legend-group-by-count">({{ ngxChart?.colorizedAttributeValues?.length }})</div>
  @if (focusedSeries?.seriesNames.length) {
    <div class="label ml-x2">
      {{ 'COMMON_MESSAGES.COUNT_SELECTED' | translate: { count: focusedSeries.seriesNames.length } }}
      <cds-icon
        (click)="clearFocusedSeries.emit()"
        class="close-icon clickable"
        shape="times"
      />
    </div>
  }
</div>
<dpa-legend-horizontal
  [ngxChart]="ngxChart"
  [focusedSeries]="focusedSeries ? focusedSeries.seriesNames : []"
  [activeSeries]="activeSeries"
  (selectSeries)="selectSeries.emit($event)"
  (activateSeries)="activateSeries.emit($event)"
  (deactivateSeries)="deactivateSeries.emit($event)"
  class="horizontal-legend"
/>
