<div class="custom-connector-info mb-x3">
  <p>
    {{ 'CONNECTIONS.CUSTOM_CONNECTORS_INFO' | translate }}
  </p>
  <a
    [href]="userGuideUrl"
    target="_blank"
    rel="noopener"
    class="btn btn-link doc-link pl-0 mb-x3"
  >
    <cds-icon shape="pop-out" />
    {{ 'CONNECTIONS.TO_LEARN_MORE_VISIT_THE_INTEGRATION_WEBSITE' | translate }}
  </a>
</div>
<div class="modal-body">
  <dpa-alert-banner [target]="ALERT_BANNER_TARGET.MODAL" />
  <form
    [formGroup]="connectorForm"
    class="clr-form form"
  >
    <dpa-inline-edit
      [autofocus]="true"
      [isInvalid]="isRequiredWarningVisible('name')"
      [invalidTooltip]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
      [placeholder]="'CUSTOM_CONNECTION.CONNECTION_NAME' | translate"
      [isRequired]="true"
      formControlName="name"
      size="lg"
    />
    <dpa-inline-edit
      [placeholder]="'COMMON_MESSAGES.ADD_DESCRIPTION_OPTIONAL' | translate"
      formControlName="description"
    />
  </form>
  <h4 class="mb-x4">
    {{ 'CUSTOM_CONNECTION.AUTHENTICATION_DETAILS' | translate }}
  </h4>
  <div class="meta-form-container">
    <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
    @if (connectorConfigSchema) {
      <dpa-dynamic-form
        [jsonSchema]="connectorConfigSchema?.jsonSchema"
        [metadata]="connectorConfigSchema?.metadata"
        (formGroupChange)="configForm = $event"
      />
    } @else {
      <div class="spinner-container-centered">
        <span class="spinner"></span>
      </div>
    }
  </div>
</div>
<div class="modal-footer">
  <button
    [disabled]="!(connectorForm?.valid && configForm?.valid)"
    [clrLoading]="isLoading"
    (click)="addCustomConnector()"
    type="button"
    class="btn btn-primary"
  >
    {{ 'CONNECTIONS.SET_UP' | translate }}
  </button>
</div>
